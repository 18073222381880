$accent-color: #3f51b5;

.form-group {
  margin: 7px;
  font-family: 'Mulish';
  margin-bottom: 6px;
}
.form-check {
  display: inline-block;
  position: relative;
  margin: 0 10px 10px;
  font-size: 16px;
  line-height: 24px;
}
.form-check-input {
  margin-right: 7px;
}

.MuiPaper-root.MuiTableContainer-root.MuiPaper-elevation1 {
  width: auto;
  // margin: 20px;
}
.MuiTableCell-head {
  color: #a73a3a;
  font-weight: bold !important;
  background-color: #4157cc1a !important;
  line-height: 1.5rem;
}

.MuiTypography-h5 {
  color: #303e8a;
  font-size: 19px !important;
  font-weight: bold !important;
  line-height: 20px;
  letter-spacing: -0.05px;
}
.dot {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
}

.tooltip-repeating-tag {
  font-size: 16px !important;
  padding: 6px !important ;
  color: rgb(255, 255, 255);
}

.toolTip1 {
  background-color: white;
  border-radius: 4px;
  text-align: center;
  padding: 3px;
  // z-index: 1000;
}

.tooltip-divider {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  margin: 2px;
  background-color: black;
}

.toolTip1Points {
  font-size: 0.7em;
}

.toolTip1Heading {
  font-weight: bold;
  font-size: 0.8em;
}

.heading1 {
  color: #000000;
  font-weight: bold !important;
  background-color: #4157cc1a !important;
  line-height: 1.5rem;
}
.actionLink {
  background: #e7f4ff;
  padding: 9px;
  text-decoration: none !important;
  border-radius: 4px;
  padding-right: 14px;
  padding-left: 14px;
}

.noUnderLine {
  text-decoration: none !important;
}
.avatar1 {
  background-color: #3f51b50f;
  width: fit-content;
  padding: 10px;
  font-size: 1.1em;
  color: #3f51b5;
  border-radius: 4px !important;
}

.avatar2 {
  width: 70px !important;
  font-size: 2.1em !important;
  height: 70px !important;
}

.avatar3 {
  width: 40px !important;
  font-size: 1.4em !important;
  height: 40px !important;
}

.cardHeaderTitle1 {
  font-family: helvetica;
  font-size: 1.7em;
  font-weight: bold;
  color: #3f51b5;
}
.cardHeaderTitle2 {
  position: relative;
  top: -9px;
  font-size: 2em;
}
.cardSubHeader1 {
  font-size: 1.4em;
  position: absolute;
  padding-left: 1px;
  color: #a7a7a7;
}
.materialBox1 {
  // background-color: #50afe20f;
  background: linear-gradient(
    90deg,
    rgba(224, 250, 255, 1) 0%,
    rgba(206, 206, 215, 0.29485297536983546) 50%,
    rgba(216, 207, 255, 0.5413515748096114) 100%
  );
  border-radius: 10px;
  padding: 30px;
  padding-bottom: 0px;
}
.boxHeader1 {
  font-size: 1.4em;
  color: #007dc1;
}
.gridText1 p {
  font-size: 1.2em;
  padding-bottom: 7px;
}
.red-tag p {
  width: fit-content;
  padding: 1px;
  color: #e00000;
  font-size: 0.9em;
  font-weight: bold;
  border-radius: 6px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #ffd6d6;
}

.green-tag p {
  width: fit-content;
  padding: 1px;
  color: green;
  font-size: 0.9em;
  font-weight: bold;
  border-radius: 6px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #c8ecc8;
}
.yellow-tag p {
  width: fit-content;
  padding: 1px;
  color: #888816;
  font-size: 0.9em;
  font-weight: bold;
  border-radius: 6px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: rgb(251, 251, 216);
}

.flag-yes-01 {
  border: 1px solid red;
  padding: 5px;
  font-size: 12px;
  border-radius: 6px;
  background: #ffe9e947;
  color: red;
}
//-------------------  common styling ----------------

.float-right {
  float: right;
}
.float-left {
  float: left;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-left {
  justify-content: left !important;
}
.justify-content-right {
  justify-content: right !important;
}
.color-white {
  background: white !important;
}
.chip1 {
  font-size: 10px !important;
  padding: 2px !important;
}
.avatar-style-01 {
  color: #ffffff !important;
}
.margin-right-10 {
  margin-right: 10px;
}

.dotFont {
  font-size: 0.8rem !important;
}

.dotFont {
  font-size: 0.8rem !important;
}

.testMargin {
  margin-left: 5px;
}

.testMarginDecoration {
  margin-left: 5px;
  text-decoration: line-through;
}

.greenText {
  margin-left: 100px;
  color: green;
}

.redText {
  margin-left: 86px;
  color: red;
}

.withTextDecoration {
  font-size: 0.8rem !important;
  text-decoration: line-through !important;
}

//-------------------  common styling ----------------

.topBarRoleBtn {
  background-color: white !important;
  margin-left: 17px !important;
  font-size: 14px !important;
  padding: 1px !important;
}

.dialogTitle1 {
  background: linear-gradient(
    90deg,
    rgba(224, 250, 255, 1) 0%,
    rgba(206, 206, 215, 0.29485297536983546) 50%,
    rgba(216, 207, 255, 0.5413515748096114) 100%
  ) !important;
  text-align: center !important;
}
.dialogTitle1 h2 {
  font-size: 1.3em !important;
}

.MuiTableCell-stickyHeader {
  background-color: #ced8e2 !important;
}
.total-records-found {
  float: right;
  position: absolute;
  border-radius: 6px;
  font-size: 13px;
  padding: 6px;
  background-color: #043378b3;
  color: white;
  margin: 13px;
}

// -------------------------  Historical Grades Scale  -------------------------
.width-of-grading-scale {
  width: 115px !important;
}
.my-legend .legend-title {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 90%;
}
.my-legend .legend-scale ul {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
}
.my-legend .legend-scale ul li {
  display: block;
  float: left;
  width: 50px;
  margin-bottom: 6px;
  text-align: center;
  font-size: 80%;
  list-style: none;
}
.my-legend ul.legend-labels li span {
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}
.my-legend .legend-source {
  font-size: 70%;
  color: #999;
  clear: both;
}
.my-legend a {
  color: #777;
}

// -------------------------  Historical Grades Scale  -------------------------

//Intervention Dash

.statusColIcons {
  top: -1px;
  left: -1px;
  position: relative;
}

//Intervention Dash

//Snack bar global

.MuiSnackbar-anchorOriginBottomCenter {
  top: 109px !important;
}
.MuiSnackbar-root {
  justify-content: flex-end !important;
  bottom: unset !important;
  transform: none !important;
  left: 8px !important;
  right: 8px !important;
}
//Snack bar global

//Scheduler
.e-btn.e-primary,
.e-css.e-btn.e-primary {
  background-color: #01163e !important;
  border-color: transparent;
  color: #fff;
}

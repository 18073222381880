.my-cron .c-host {
  display: flex;
  width: 623px;
  flex-direction: column;
  ul.nav.nav-tabs.mb-2.c-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #01163e;
    font-weight: bold;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  .c-tabs {
    flex-direction: row;
    border: 0;
    .c-tab {
      text-align: left;
      border: 0;
      border-radius: 0;
      width: 100%;
      border-radius: 0;
      text-align: center;
      padding: 12px;
      border: 1px solid white;
      cursor: pointer;
      border-radius: 7px;
      background-color: #eceff1;
    }
  }
  .c-tab-content {
    padding-top: 0.5rem;
    padding-left: 1rem;
  }
}
.row.pl-3.pt-1 {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
select {
  font: inherit;
  color: currentColor;
  border: 0;
  height: 22px;
  margin: 0;
  margin-left: 2px;
  margin-right: 9px;
  padding: 4px 5px 4px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.card1 {
  margin: 10px;
}
// p.MuiTypography-root.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-gutterBottom {
//   margin-left: 31px;
//   font-size: 12px;
//   background-color: blue;
// }

#recordsLabel {
  margin-left: 31px;
  font-size: 12px;
  // background-color: red;
}
.card1 {
  margin: 10px;
}
p.MuiTypography-root.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-gutterBottom {
  margin-left: 31px;
  font-size: 12px;
}

.avgAndGraph {
  display: flex;
  text-align: center;
}
.averageBox {
  width: 35%;
  margin-top: 48px;
  height: 300px !important;
  margin-left: 15px;
}
.averageBoxContent {
  text-align: left;
  position: relative;
  padding: 3px;
  left: 1vw;
}
.label1 {
  font-size: 1.3vw;
  position: relative;
  line-height: 40px;
  color: #4c7da9;
  font-weight: bold;
}

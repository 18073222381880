.dndflow {
  flex-direction: column;
  display: flex;
  height: 100%;
  margin-bottom: 70px;
}

.dndflow aside {
  border-right: 1px solid #eee;
  // padding: 15px 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  background: #f4f6f7;
  text-align: center;
  justify-content: center;
  height: 69vh;
  overflow: overlay;
}

.dndflow aside .description {
  color: grey;
  font-size: 14px;
  margin-bottom: 15px;
}

.dndflow .dndnode {
  display: block;
  border-top: 1px solid white;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: grab;
  border-bottom: 1px solid white;
}

.dndflow .dndnode.input {
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.react-flow {
  border-bottom: 1px solid rgb(243, 244, 245) !important;
  height: 70vh !important;
}
.react-flow__node {
  background-color: #f4f6f7 !important;
  border-radius: 8px !important;
}

.react-flow__handle {
  width: 12px !important;
  height: 12px !important;
  background: #ffffff !important;
}

.react-flow__edge-path {
  stroke: #a1a1a1 !important;
  stroke-width: 3px !important;
}

.react-flow__node.selected.selectable {
  box-shadow: 3px 5px 20px 0px #b9bec6;
}

.MuiToggleButton-label {
  z-index: -10 !important;
}
.MuiToggleButton-root.Mui-selected {
  // color: #212529;
  font-weight: bold;
  // background-color: #ab3f434f !important;
}
.toggleBtnCustom {
  font-size: 13px !important;
  padding: 13px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.toggleBtnGroupCustom {
  font-size: 12px !important;
  height: 40px !important;
}
/*disable-link css*/
.disabled-link {
  pointer-events: none;
  button {
    background-color: #c7c7c7 !important;
  }
}

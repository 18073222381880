.card1 {
  margin: 10px;
}
p.MuiTypography-root.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-gutterBottom {
  margin-left: 31px;
  font-size: 12px;
}

.avgAndGraph {
  display: flex;
  text-align: center;
}

.label1 {
  font-size: 1.3vw;
  position: relative;
  line-height: 40px;
  color: #4c7da9;
  font-weight: bold;
}
.repeating-tag {
  font-size: 0.8em;
  padding: 4px;
  color: red;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 6px;
  background-color: #ffdcdc;
}
.careNotes {
  right: 73px !important;
  background-color: #ffffff !important;
  color: #3284e0 !important;
  border: 1px solid #74b5ff !important;
  width: 249px !important;
  top: 74px !important;
}
.ulList1 {
  max-height: 400px !important;
}
.tabPanel1 {
  background: white !important;
  text-align: center;
  width: 98%;
  margin: 9px;
}

.layoutflow {
  flex-grow: 1;
  position: relative;
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

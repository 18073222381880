.graph-tooltip {
  padding: 10px;
  & > * {
    display: block;
    white-space: nowrap;
  }

  .name {
    font-weight: bolder;
  }
}

table {
  width: 100%;
}

.csv-button {
  float: right;
  margin-bottom: 20px;
}

.progress-header {
  width: 450px;
}

td {
  .progress {
    border-radius: 15px;
    height: 30px;
    font-weight: bolder;

    .progress-bar {
      background-color: black;
      opacity: 0.4;
    }
  }
}
